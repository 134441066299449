import Vue from 'vue';
import { v4 } from 'uuid';
import TYPES from '@/types';
import { emailFormat, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription
  from '@/modules/my-investment/kuspit/kuspit-account-linking/application/subscriptions/publish-received-status-to-event-bus-on-kuspit-account-linking-status-web-socket-message-subscription';
import validateKuspitAccountCommand
  from '@/modules/my-investment/kuspit/kuspit-account-linking/application/commands/validate-kuspit-account-command';
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';
import { CreateOnBoardingStepCommand } from '@/modules/my-investment/on-boarding-steps/application/commands';
import { SearchInvestmentProvidersQuery } from '@/modules/my-investment/investment-provider/application/queries';
import { SearchOnBoardingStepsQuery } from '@/modules/my-investment/on-boarding-steps/application/queries';
import UpdateKuspitOnBoardingStepCommand
  from '@/modules/my-investment/kuspit/kuspit-account-linking/application/commands/update-kuspit-on-boarding-step-command';

// Domain
import {
  OnBoardingStepKuspitAccountEntity,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/entity/on-boarding-step-kuspit-account-entity';
import { ValidateKuspitAccountDto }
  from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/dtos/validate-kuspit-account-dto';
import { AgreementEntity } from '@/modules/my-investment/agreements/domain/entities/agreement-entity';
import { CustomerAgreementEntity } from '@/modules/on-boarding/customer-agreements/domain/entities/customer-agreement-entity';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { OnBoardingStepEntity } from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import { InvestmentProviderEntity } from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class KuspitAccountLinkingDataViewModel {
  @Inject(TYPES.VALIDATE_KUSPIT_ACCOUNT_COMMAND)
  private readonly validate_kuspit_account_command!: validateKuspitAccountCommand;

  // eslint-disable-next-line max-len
  @Inject(TYPES.PUBLISH_RECEIVED_STATUS_TO_EVENT_BUS_ON_KUSPIT_ACCOUNT_LINKING_STATUS_WEB_SOCKET_MESSAGE_SUBSCRIPTION)
  // eslint-disable-next-line max-len
  readonly received_status_to_event_bus_kuspit_account!: PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription;

  @Inject(TYPES.UPDATE_KUSPIT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateKuspitOnBoardingStepCommand;

  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  readonly search_agreements_async_query!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  i18n_namespace = 'components.contract-savings.kuspit-account-linking.account_linking_data';

  is_open = true;

  inputs = {
    email: '',
    password: '',
  };

  rules = {
    email: [requiredRule, emailFormat],
    password: [requiredRule],
  };

  valid_form = false;

  show_password = false;

  confirm_terms = false;

  investment_provider_id = '';

  exists_step = false;

  exists_step_started = false;

  private user_agreement: CustomerAgreementEntity = {
    accepted_on: null,
    agreement_type_id: '',
    was_accepted: false,
    id: '',
    description: '',
  };

  private on_boarding_step: OnBoardingStepKuspitAccountEntity = {
    id: v4(),
    current_step: 'on_boarding_kuspit_terms_and_conditions',
    investment_provider_id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    payload: {},
  }

  private on_boarding_step_started: OnBoardingStepKuspitAccountEntity = {
    id: v4(),
    current_step: 'on_boarding_kuspit_status',
    investment_provider_id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    payload: {
      id: sessionStorage.getItem('user_id')!,
      email_address: '',
      status: 'started',
    },
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  endProcess= () => {
    this.view.$emit('endProcess');
  }

  showForgetPassword = () => {
    this.is_open = false;
  }

  showPassword = () => {
    this.show_password = !this.show_password;
  }

  sendInformation= async () => {
    const step = await this.setStepOnBoarding();
    if (step) {
      this.view.$emit('nextStep');
      await this.validateKuspitAccount();
    }
  }

  get validForm() {
    return this.valid_form && this.confirm_terms;
  }

  reset = () => {
    this.inputs.email = '';
    this.inputs.password = '';
    this.confirm_terms = false;
  }

  validateKuspitAccount = async () => {
    try {
      const validate_kuspit_account_dto: ValidateKuspitAccountDto = {
        id: sessionStorage.getItem('user_id')!,
        email_address: this.inputs.email,
        password: this.inputs.password,
      };

      await this.received_status_to_event_bus_kuspit_account.execute(sessionStorage.getItem('user_id')!);
      await this.validate_kuspit_account_command.execute(validate_kuspit_account_dto);

      return true;
    } catch {
      return false;
    } finally {
      this.reset();
    }
  }

  confirmTerms = (was_accepted: boolean) => {
    this.user_agreement.was_accepted = was_accepted;
    this.user_agreement.accepted_on = this.datetime_value.create();
  };

  setUserAgreement = (new_agreements: Array<AgreementEntity>) => {
    if (new_agreements.length > 0) {
      const agreement = new_agreements.find(
        (item) => item.name === 'permission_to_link_kuspit_account',
      );
      if (agreement) {
        this.user_agreement.id = v4();
        this.user_agreement.agreement_type_id = agreement.id;
        this.user_agreement.description = agreement.description;
      }
    }
  };

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === 'kuspit',
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
        this.on_boarding_step.investment_provider_id = search_provider.id;
        this.on_boarding_step_started.investment_provider_id = search_provider.id;
      }
    }
  };

  setStepOnBoardingId = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === 'on_boarding_kuspit_terms_and_conditions',
    );

    if (search_step) {
      this.on_boarding_step.id = search_step.id!;
      this.exists_step = true;
    }

    const search_step_status = on_boarding_steps.find(
      (step) => step.current_step === 'on_boarding_kuspit_status',
    );

    if (search_step_status) {
      this.on_boarding_step_started.id = search_step_status.id!;
      this.exists_step_started = true;
    }
  };

  setStepOnBoarding = async () => {
    try {
      if (!this.exists_step_started) {
        this.on_boarding_step_started.id = v4();
        this.on_boarding_step_started.payload = {
          id: sessionStorage.getItem('user_id')!,
          email_address: this.inputs.email,
          status: 'started',
        };
        await this.create_on_boarding_step_command.execute(this.on_boarding_step_started);
        this.exists_step_started = true;
      }

      if (this.exists_step) {
        this.on_boarding_step.payload = {
          agreements: [this.user_agreement],
        };
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        this.on_boarding_step.id = v4();
        this.on_boarding_step.investment_provider_id = this.investment_provider_id;
        this.on_boarding_step.payload = {
          agreements: [this.user_agreement],
        };
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
        this.exists_step = true;
      }
    } catch {
      return false;
    }
    return true;
  }

  toLowerCaseWithoutAccents = (value: string) => {
    const normalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    this.inputs.email = normalized.toLowerCase();
  }

  initialize = async () => {
    const investment_providers = await this.search_investment_providers_query.execute();
    this.setInvestmentProviderId(investment_providers);

    const agreements = await this.search_agreements_async_query.execute();
    this.setUserAgreement(agreements);

    const step = await this.search_on_boarding_steps_query.execute(this.investment_provider_id);
    this.setStepOnBoardingId(step);
  }
}
