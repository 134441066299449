










































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import KuspitAccountLinkingDataViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-data-view-model';

@Component({
  name: 'KuspitAccountLinkingData',
  components: {
    kuspitAccountLinkingForgetPassword: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingForgetPassword.vue'),
  },
})
export default class KuspitAccountLinkingData extends Vue {
  kuspit_data_view_model = Vue.observable(
    new KuspitAccountLinkingDataViewModel(this),
  );

  async created() {
    await this.kuspit_data_view_model.initialize();
  }
}
